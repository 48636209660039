
































































































































































































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import {Cell, Company, Entity, Datum, EntityRootName} from "@/smartmsi";
import SpreadSheets from "@/services/SpreadSheets";
import draggable from 'vuedraggable';
import {downloadToDisk} from "@/utils/download";
import AddBranch from '@/components/variation/AddBranch.vue';

type CellMap = Map<string, Cell>;

const makeCell = (key: string, e: Entity, year: string, sheet: string, x: number, y: number) => {
  const cell = e.data[year] as Cell;
  cell.entity = e;
  cell.key = key;
  cell.value = undefined !== cell.value ? parseFloat(cell.value) : null;
  cell.el = document.getElementById(key) as HTMLElement;
  cell.sheet = sheet;
  cell.x = x;
  cell.y = y;
  return cell;
}

const getAllCodes = (entities: Entity[]) => {
  let codes = entities.map(x => x.code);
  entities.forEach(x => {
    if (x.children?.length) codes = [...codes, ...getAllCodes(x.children)];
  })
  return codes;
}

const removeCodes = (entities: Entity[], codes: string[]) => {
  const f = entities.filter(x => !codes.includes(x.code) );
  f.forEach(x => { if (x.children?.length) removeCodes(x.children, codes); });
  return f;
}

const flatList: Entity[] = [];

@Component({mixins: [formatter], components: { draggable, AddBranch }})
export default class CompanyData extends Vue {

  @Prop() company!: Company;
  @Prop({default: false}) config?: boolean;
  @Prop() lists!: {[s: string]: Entity[]};
  @Prop() data!: {
    financialStatements: Entity[];
    aggregateHeadings: Entity[];
    axes: Entity[];
  };

  tab = 0;
  showDialog = false;
  editedFormula = null;
  
  years = this.company?.availableYears || [2017,2018,2019];
  yearsOrderUp = false;
  minYear = this.years[0];
  maxYear = this.years[this.years.length-1];
  yearsRange: number[] = [];
  addedYears: number[] = [];
  
  dialog = false;
  addDialog = false;
  editedEntity!: Entity | null = null;
  realModel!: Entity;

  addType!: EntityRootName;
  addParent!: Entity | undefined;
  branchesToAdd: Entity[] = [];
  
  instView = 'viewValue';

  // @ts-ignore
  cellDetails: Cell = null;
  debugDetails = false;

  computer!: SpreadSheets;

  isMounted = false;

  created() {
    this.computer = new SpreadSheets(this, {
      onDataChange: this.computerDataChangeCallback,
    });
    this.onYearsOrderChange();
    this.yearsRange = [0, Math.min(3, this.maxYear - this.minYear)];
    this.$nextTick(() => {
      this.initComputer();
    })

    // flatten lists for faster branch adding
    if (this.lists) {
      Object.values(this.lists).forEach((l1s: Entity[]) => {
        l1s.forEach((l1: Entity) => {
          flatList.push(l1);
          l1.children.forEach((l2: Entity) => {
            flatList.push({ ...l2, parentCode: l1.code });
            l2.children.forEach((l3: Entity) => {
              flatList.push({ ...l3, parentCode: l2.code });
            })
          })
        })
      })
    }
  }

  initComputer() {

    const cells: CellMap = new Map();

    let x: number,
        y: number;

    y = 0;

    this.data.financialStatements.forEach(fs => {
      fs.children.forEach(fh => {
        fh.parent = fs;
        fh.children.forEach(fa => {
          fa.parent = fh;
          x = 0;
          Object.keys(fa.data).forEach((year: string) => {
            const key = `fsa:${fa.id}:${year}`;
            cells.set(key, makeCell(key, fa, year, 'fin', x++, y));
          });
          y++;
        });
      });
    });

    y = 0;
    this.data.aggregateHeadings.forEach(ah => {
      ah.children.forEach(agg => {
        agg.parent = ah;
        x = 0;
        Object.keys(agg.data).forEach((year: string) => {
          const key = `agg:${agg.id}:${year}`;
          cells.set(key, makeCell(key, agg, year, 'agg', x++, y));
        });
        y++;
      });
    });
    y = 0;

    this.data.axes.forEach(axe => {
      axe.children.forEach(theme => {
        theme.parent = axe;
        theme.children.forEach(instrument => {
          instrument.parent = theme;
          x = 0;
          Object.keys(instrument.data).forEach((year: string) => {
            const key = `inst:${instrument.id}:${year}`;
            cells.set(key, makeCell(key, instrument, year, 'inst', x++, y));
          });
          y++;
        });
      });
    });

    this.$nextTick(() => {
      this.computer.addCells(cells);
    })
  }

  computerDataChangeCallback(cells: CellMap) {
    let instumentsHaveChanged = false;

    cells.forEach((cell, key) => {
      if (key.match(/^inst:/)) instumentsHaveChanged = true;

      if (cell.el) {
        const el = cell.el;

        // dump all infos in the title
        // el.setAttribute('title', JSON.stringify(cell, null, '  '));

        // the value was changed ?
        const modified = cell.value !== cell.originalValue;
        el.classList.toggle('value-modified', modified);

        // the value was forced ?
        el.classList.toggle('value-forced', !!cell.forced);

        const different = !!(cell.originalDisplayValue && (cell.displayValue !== cell.originalDisplayValue));
        el.classList.toggle('computed-different', different && cell.originalValue != -123456789 && !modified);

        if (different && cell.originalValue !== null && !modified) {
          el.innerHTML = '<del>' + cell.originalDisplayValue + '</del><br>' + cell.displayValue;
        } else {
          el.innerText = cell.displayValue as string;
        }

        if (cell.axeId) {
          el.innerHTML = `
            <div class="data-value">${cell.displayValue}</div>
            <div class="data-score">${cell.computedScore}</div>
            <div class="data-eval">${(cell.computedEvaluation as number).toFixed(2)}</div>
            `;

          if (cell.computedEvaluation === 0 || cell.computedEvaluation === 10)
            el.dataset.color = 'yellow';
          else if (cell.computedEvaluation as number >= 5)
            el.dataset.color = 'green';
          else
            el.dataset.color = 'red';
        }
      }
    });

    if (instumentsHaveChanged) {
      this.$emit('instrumentsChange', this.data.axes);
    }
  }

  updateInstCells(inst: Entity) {
    const changedCells: CellMap = new Map();
    Object.values(inst.data).forEach((cell: Datum) => {
      changedCells.set(cell.key, cell);
    });
    this.computer.addCells(changedCells);
  }

  addYear() {
    const year = ++this.maxYear;
    this.years.push(year);
    this.addedYears.push(year);
    this.$set(this.yearsRange, 1, this.yearsRange[1]+1);


    const cells: CellMap = new Map();

    const x = year - this.minYear;
    let y = 0;

    this.data.financialStatements.forEach(fs => {
      fs.children.forEach(fh => {
        fh.children.forEach(fa => {
          const key = `fsa:${fa.id}:${year}`;
          const cell = {
            entity_id: fa.id,
            entity: fa,
            value: null,
            year: year, 
            name: this.translateName(fa) + ' [' + year + ']',
            sheet: 'fin',
            key: key,
            x: x,
            y: y,
          };

          cells.set(key, cell);
          fa.data[year] = cell;
          y++;
        });
      });
    });

    y = 0;
    this.data.aggregateHeadings.forEach(ah => {
      ah.children.forEach(agg => {
        const key = `agg:${agg.id}:${year}`;
        const cell = { 
          entity_id: agg.id,
          entity: agg,
          value: null,
          year: year, 
          name: this.translateName(agg) + ' [' + year + ']',
          key: key,
          sheet: 'agg',
          x: x,
          y: y,
        };

        cells.set(key, cell);
        agg.data[year] = cell;
        y++;
      });
    });

    y = 0;
    this.data.axes.forEach(axe => {
      axe.children.forEach(theme => {
        theme.children.forEach(instrument => {
          const key = `inst:${instrument.id}:${year}`;
          const cell = {
            entity_id: instrument.id,
            entity: instrument,
            value: null,
            score: null,
            evaluation: null,            
            year: year,
            themeId: theme.id,
            axeId: axe.id,
            name: this.translateName(instrument) + ' [' + year + ']',
            key: key,
            sheet: 'inst',
            x: x,
            y: y,
          };

          cells.set(key, cell);
          instrument.data[year] = cell;
          y++;
        });
      });
    });

    this.$nextTick(() => {
      this.computer.addCells(cells);
    })
  }

  removeYear() {
    const year = this.years.pop() as number;
    this.maxYear--;
    this.addedYears.pop();
    if (this.yearsOrderUp) {
      this.$set(this.yearsRange, 0, this.yearsRange[0]+1);
    } else {
      this.$set(this.yearsRange, 1, this.yearsRange[1]-1);
    }


    this.data.financialStatements.forEach(fs =>
      fs.children.forEach(fh =>
        fh.children.forEach(fa => delete fa.data[year])
      )
    );

    this.data.aggregateHeadings.forEach(ah =>
      ah.children.forEach(agg => delete agg.data[year])
    );

    this.data.axes.forEach(axe =>
      axe.children.forEach(theme =>
        theme.children = theme.children.filter(inst => delete inst.data[year])
      )
    );

    this.computer.removeYear(year);
  }

  trcls(x: Entity, i: number) {
    return {
      expanded: !!x._expand,
      [x.type]: true, 
      odd: i%2,
    };
  }

  exportData() {
    const res = {
      'financialStatements': {} as Record<number, Entity>[],
      'aggregates': {} as Record<number, Entity>[],
      'instruments': {} as Record<number, Entity>[],
    };

    this.data.financialStatements.forEach(fs => {
      fs.children.forEach(fh => {
        fh.children.forEach(fa => {
          res.financialStatements[fa.id] = {};
          Object.keys(fa.data).forEach(year => {
            const key = `fsa:${fa.id}:${year}`;
            res.financialStatements[fa.id][year] = this.computer.getDetails(key);
          });
        });
      });
    });

    this.data.aggregateHeadings.forEach(ah => {
      ah.children.forEach(agg => {
        res.aggregates[agg.id] = {};
        Object.keys(agg.data).forEach(year => {
          const key = `agg:${agg.id}:${year}`;
          res.aggregates[agg.id][year] = this.computer.getDetails(key);
        });
      });
    });

    this.data.axes.forEach(axe => {
      axe.children.forEach(theme => {
        theme.children.forEach(instrument => {
          res.instruments[instrument.id] = {};
          Object.keys(instrument.data).forEach(year => {
            const key = `inst:${instrument.id}:${year}`;
            res.instruments[instrument.id][year] = this.computer.getDetails(key);
          });
        });
      });
    });

    this.$api.post('company/download-analysis?id=' + this.company.id, {
      data: res,
      type: 'full',
      config: {
        target_year: 2019,
        forecast: 3,
        instrument_start: 2015,
        // From what year do we start in the Excel file
        analysis_start: 2010,
        theme_start: 2010,
        theme_table_start: 2012,
        conclusion_start: 2010,
        certification_authority_id: 3,

        destinationFolder: '/Users/didou/Dev/sites/mehdi/smartmsi.lcl/www/',

        language: 'fr',
        document_date: '2021-04',
        accent_color: 'FFC501',
        audit_type: 'followup',
      },
    }, {
      responseType: 'blob',
    }).then(function (response) {
      console.log('SUCCESS!!');
      downloadToDisk(response);
    }).catch(function () {
      console.log('FAILURE!!');
    });
  }

  get formulaEditorConfig() {
    return {
      financialStatements: this.data.financialStatements,
      aggregateHeadings: this.data.aggregateHeadings,
      axes: this.data.axes,
    }
  }

  showCellDetails(key: string) {
    this.cellDetails = this.computer.getDetails(key);
    this.showDialog = true;
  }

  // prompt for new value and update computer
  selectCell(key: string) {
    this.computer.selectCell(key);
  }

  onBlur() {
    this.computer.selectCell('');
  }

  onCellKeyDown(e: KeyboardEvent) {
    this.computer.keyboardEventHandler(e);
  }

  toggleExpand(el: Entity, value?: boolean) {
    if (undefined === value)
      this.$set(el, '_expand', !el._expand);
    else
      this.$set(el, '_expand', value);
  }

  expand(data: string, level: number) {
    // @ts-ignore
    this.data[data].forEach((l1: Entity) => {
      this.$set(l1, '_expand', level > 0);
      if (l1.children) {
        l1.children.forEach((l2: Entity) => {
          this.$set(l2, '_expand', level > 1);
        });
      }
    });
  }


  getJson() {
    // remove spreadsheet properties + avoid circular reference
    const cleaner = (key: string, value: any) => {
      if (['entity', 'parent', 'dependencies', 'key', 'el', 'sheet', 'x', 'y', 'expand', 'computedFormula', 'namesFormula'].includes(key)) return undefined;
      if (key.match(/^original/)) return undefined;
      return value;
    }

    const json = {
      financialStatements: JSON.parse(JSON.stringify(this.data.financialStatements, cleaner)),
      aggregateHeadings:   JSON.parse(JSON.stringify(this.data.aggregateHeadings, cleaner)),
      axes:                JSON.parse(JSON.stringify(this.data.axes, cleaner)),
    };
    
    this.$api.patch(`companies/data?id=${this.company.id}`, json)
    .then(() => {
      alert('Data saved with success');
      this.$router.push('/company');
    })
    .catch(err => {
      alert('There were errors while saving. More infos in console');
      console.error('SAVE ERROR');
      console.dir(JSON.parse(err.response.data.message));
    });
  }

  add(type: EntityRootName, parent?: Entity) {
    this.addType = type;
    this.addParent = parent;

    const currentCodes = getAllCodes([...this.data.financialStatements, ...this.data.aggregateHeadings, ...this.data.axes]);

    if (parent) {
        const branches = flatList.filter(x => x.parentCode === parent.code);
        this.branchesToAdd = removeCodes(branches, currentCodes);
    } else {
        this.branchesToAdd = removeCodes(this.lists[type], currentCodes);
    }

    this.addDialog = true;
  }

  onAdd(branches: Entity[]) {
    this.addDialog = false;
    if (this.addParent) {
      this.addParent.children = [...this.addParent.children, ...branches];
    } else {
      this.data[this.addType] = [ ...this.data[this.addType], ...branches];
    }
  }

  editName(model: Entity) {
    this.realModel = model;
    this.editedEntity = {
      code: model.code,
      name_fr: model.name_fr,
      name_en: model.name_en,
    } as Entity;
    this.dialog = true;
  }

  saveName() {
    this.realModel.code = this.editedEntity.code;
    this.realModel.name_fr = this.editedEntity.name_fr;
    this.realModel.name_en = this.editedEntity.name_en;
  }

  deleteEntity(entities: Entity[], idx: number) {
    if (confirm('Delete ' + this.translateName(entities[idx]) + ' ?')) {
      this.$delete(entities, idx);
    }
  }

  get canAddYear() {
    return this.years[this.years.length-1] < ((new Date()).getFullYear() - 1);
  }

  isYearVisible(year: number) {
    const [min, max] = this.yearsRange;
    return this.yearsOrderUp 
      ? year >= (this.minYear+min) && year <= (this.minYear+max)
      : year <= (this.maxYear-min) && year >= (this.maxYear-max);
  }

  get yearsCols() {
    const years = [...this.years];
    return this.yearsOrderUp ? years : years.reverse();
  }

  @Watch("yearsOrderUp")
  onYearsOrderChange() {
    this.computer.colsDirection = this.yearsOrderUp ? 1 : -1;
    const [min, max] = this.yearsRange;
    const range = this.maxYear - this.minYear;
    this.yearsRange = [range-max, range-min];
  }
}
