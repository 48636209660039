import {Cell, ComputerOptions, DataChangeEventHandler} from "@/smartmsi";
import {fromExcelFormat} from "@/utils/excel-format";
import Vue from "vue/types/umd";

type CellMap = Map<string, Cell>;

class SpreadSheets {

  cells: CellMap = new Map();
  cellsMap: CellMap = new Map();
  themesCells: Map<string, Set<Cell>> = new Map();
  axesCells: Map<string, Set<Cell>> = new Map();
  themesToUpdate: Map<number, Set<number>> = new Map();
  axesToUpdate: Map<number, Set<number>> = new Map();
  component: Vue;

  // @ts-ignore
  axeThemeUpdateTimer!: Timeout;

  callBack: DataChangeEventHandler;

  changedCells: CellMap = new Map();
  selectedCell?: Cell;
  editedCell?: Cell;
  colsDirection = 1;

  constructor(component: Vue, options: ComputerOptions) {
    this.component = component;
    this.callBack = options.onDataChange;
  }

  addCells(cells: CellMap) {
    cells.forEach(cell => this.registerCell(cell));
    this.optimize(cells);
    this.computeAll(cells);
    this.callBack(cells);
  }

  registerCell(cell: Cell) {
    this.cells.set(cell.key, cell);
    this.cellsMap.set(`${cell.sheet}:${cell.x}:${cell.y}`, cell);

    if ('instrument' === cell.entity.type) {
      cell.themeId = cell.entity.parent.id;
      cell.axeId = cell.entity.parent.parent.id;
      const themeKey = cell.themeId + ':' + cell.year;
      const axeKey = cell.axeId + ':' + cell.year;
      if (!this.themesCells.has(themeKey)) this.themesCells.set(themeKey, new Set());
      this.themesCells.get(themeKey)?.add(cell);

      if (!this.axesCells.has(axeKey)) this.axesCells.set(axeKey, new Set());
      this.axesCells.get(axeKey)?.add(cell);

      if (!this.themesToUpdate.has(cell.themeId)) this.themesToUpdate.set(cell.themeId, new Set());
      if (!this.axesToUpdate.has(cell.axeId)) this.axesToUpdate.set(cell.axeId, new Set());
    }
  }

  unregisterCell(cell: Cell) {
    this.cells.delete(cell.key);
    this.cellsMap.delete(`${cell.sheet}:${cell.x}:${cell.y}`);

    if (cell.themeId) {
      this.themesCells.get(cell.themeId + ':' + cell.year)?.delete(cell);
      this.axesCells.get(cell.axeId + ':' + cell.year)?.delete(cell);
    }
  }

  /**
   * - transforms formulas to ready to use js code
   * - build an array of cells dependent on current cell
   */
  optimize(cells: CellMap) {
    cells.forEach(cell => {

      // initialize computedValue from value
      cell.computedValue = cell.value;
      if (null !== cell.originalValue) {
        cell.originalValue = cell.value;
        cell.originalDisplayValue = fromExcelFormat(cell.originalValue, cell.entity.excel_format);
      } else {
        cell.originalDisplayValue = '';
      }

      cell.el = document.getElementById(cell.key) as HTMLTableDataCellElement;
      if (cell.themeId)
        cell.themeEl = document.getElementById(`theme:${cell.themeId}:${cell.year}`) as HTMLTableDataCellElement;
      if (cell.axeId)
        cell.axeEl = document.getElementById(`axe:${cell.axeId}:${cell.year}`) as HTMLTableDataCellElement;


      if (cell.entity.formula) {
        const formReg = /{.*?}/g;
        cell.computedFormula = cell.entity.formula.replace(formReg, match => {

          let year = cell.year;

          // extract -y if needed
          if (match.match(/-(\d)/)) {
            // @ts-ignore
            year -= parseInt(match.match(/-(\d)/)[1]);
            match = match.replace(/-(\d)/, '');
          }

          // build new key
          const key = match.substring(1, match.length - 1) + ':' + year;

          // inform other cell that this one depends of its value
          if (this.cells.has(key)) {
            // @ts-ignore
            if (!this.cells.get(key).dependencies) this.cells.get(key).dependencies = [];
            // only add dependencies once
            // @ts-ignore
            if (!this.cells.get(key).dependencies?.find(c => c.key === cell.key)) {
              // @ts-ignore
              this.cells.get(key).dependencies.push(cell);
            }
          }

          // add depencies variables
          if (!cell.depValues) cell.depValues = new Set();
          cell.depValues.add(`SpreadSheets.cellValue(this.cells.get('${key}'))`);


          return `SpreadSheets.cellValue(this.cells.get('${key}'))`;
        });
        cell.namesFormula = cell.entity.formula.replace(formReg, match => {

          let year = cell.year;

          // extract -y if needed
          if (match.match(/-(\d)/)) {
            // @ts-ignore
            year -= parseInt(match.match(/-(\d)/)[1]);
            match = match.replace(/-(\d)/, '');
          }

          // build new key
          const key = match.substring(1, match.length - 1) + ':' + year;
          // @ts-ignore
          const name = this.cells.has(key) ? this.component.translateName(this.cells.get(key).entity) : '??';
          return `<var>${name}</var>`;
        });
      }
    });
  }

  computeAll(cells: CellMap) {
    cells.forEach(cell => {
      this.computeCell(cell, false);
    });
  }

  getCell(key: string) {
    return this.cells.get(key);
  }

  private static cellValue(cell: Cell): number {
    return (cell.entity.formula && !cell.forced ? cell.computedValue : cell.value) as number;
  }

  // sets one or multiple cells
  setCell(key: string, v: string | number) {
    if ('string' === typeof v) {
      const lines = v.trim().split('\n');
      // multiple valid values ?
      if (lines.length && lines.every(line => false !== this.getNumber(line))) {
        const firstCell = this.cells.get(key) as Cell;
        const {sheet, x, y} = firstCell;
        lines.forEach((line, idx) => {
          const k = this.cellsMap.get(`${sheet}:${x}:${y + idx}`)?.key;
          if (k) this._setCell(k, this.getNumber(line) as number);
        })
      } else if (false !== this.getNumber(v)) {
        this._setCell(key, this.getNumber(v) as number);
      } else {
        alert('invalid clipboard content')
      }
    } else {
      this._setCell(key, v);
    }
  }

  getNumber(v: string): number | false {
    return v.match(/^-?\d+(\.\d+)?$/) ? parseFloat(v) : false;
  }

  // actually sets the cell
  _setCell(k: string, v: number | string): void {

    // we only deal with numbers for now
    if ('string' === typeof v) {
      v = parseFloat(v);
    }

    const cell = this.cells.get(k) as Cell;

    // has a formula => force
    if (cell.entity.formula) {
      cell.forced = true;
    }
    cell.value = v;

    // start gathering list of changed cells
    this.computeCell(cell, true);
    this.formatMultiple(this.changedCells);
    this.callBack(this.changedCells);
    this.changedCells.clear();
  }

  selectCell(key: string) {

    // already selected ?
    if (this.selectedCell && this.selectedCell.key === key) {
      if (!this.editedCell) this.setEditMode(true);
      return;
    }

    if ('' === key) {
      if (this.selectedCell) {
        this.selectedCell.el?.blur();
        delete this.selectedCell;
      }
      if (this.editedCell) {
        const key = this.editedCell.key;
        const el = this.editedCell.el as HTMLTableCellElement;
        const val = el.innerText;
        this.setCell(key, parseFloat(val + ''));
        el.contentEditable = 'false';
        delete this.editedCell;
      }
      return;
    }

    this.selectedCell = this.cells.get(key);
    if (this.selectedCell) this.selectedCell.el?.focus();
  }

  selectNeighbour(X: number, Y: number) {
    if (this.selectedCell) {
      const {x, y, sheet} = this.selectedCell;
      const cell = this.cellsMap.get(`${sheet}:${x + X}:${y + Y}`);
      if (cell && cell.el) {
        this.selectCell(cell.key);
      }
    }
  }

  // creates a .computedValue attribute with value or result of formula
  computeCell(cell: Cell, propagate?: boolean) {
    // has formula ?
    if (cell.computedFormula && !cell.forced) {

      // test if any of dependencies is null
      let canCompute = true;
      const values = Array.from(cell.depValues);
      for (let i = 0; i < values.length; i++) {
        if (null === eval(values[i])) {
          canCompute = false;
          break;
        }
      }

      if (canCompute) {
        try {
          cell.computedValue = eval(cell.computedFormula);
          cell.computeError = false;
        } catch (e) {
          console.error(e);
          cell.computeError = true;
        }
      } else {
        cell.computedValue = null;
      }
    } else {
      cell.computedValue = cell.value;
    }

    if (propagate && cell.dependencies) {
      // @ts-ignore
      cell.dependencies.forEach(dependent => {
        this.computeCell(dependent, true);
      });
    }

    // update Global Scores ?
    if ('instrument' === cell.entity.type) {
      this.updateCellScore(cell);
    }

    this.format(cell);
    this.changedCells.set(cell.key, cell);
  }

  updateCellScore(cell: Cell) {
    // console.log('update score', cell.key)

    const referent = parseFloat(cell.entity.ref_val) as number;
    const coef = parseFloat(cell.entity.coef) as number;
    const sign = cell.entity.ref_comp === '<' ? -1 : 1;
    const evaluation = Math.max(0, Math.min(10, (1 + (sign * (cell.computedValue as number - referent) / referent)) * 5));

    cell.computedEvaluation = this.truncate(evaluation);
    cell.computedScore = this.truncate(evaluation * coef);

    this.themesToUpdate.get(cell.themeId)?.add(cell.year);
    this.axesToUpdate.get(cell.axeId)?.add(cell.year);
    this.axeThemeUpdateTimer = setTimeout(this.updateThemesAndAxes.bind(this), 300);
  }

  updateThemeScore(themeId: number, year: number) {
    // update theme score
    // console.log('update theme', themeId, year)
    const themeCells = this.themesCells.get(themeId + ':' + year) as Set<Cell>;

    let scoreSum = 0;
    let coefSum = 0;
    themeCells.forEach((c: Cell) => {
      scoreSum += parseFloat(c.computedScore);
      coefSum += parseFloat(c.entity.coef);
    });
    const cell = Array.from(themeCells)[0] as Cell;
    if (cell.themeEl) cell.themeEl.innerText = (scoreSum / coefSum).toFixed(5);
    themeCells.forEach((c: Cell) => {
      c.themeForAxeScore = scoreSum;
    });
  }

  updateAxeScore(axeId: number, year: number) {
    // update axe score
    // console.log('update axe', axeId, year)
    const axeCells = this.axesCells.get(axeId + ':' + year) as Set<Cell>;
    const foundThemes: number[] = [];
    let sumThemes = 0;
    axeCells.forEach((c: Cell) => {
      if (!foundThemes.includes(c.themeId as number)) {
        sumThemes += c.themeForAxeScore || 0;
        foundThemes.push(c.themeId as number);
      }
    });
    const cell = Array.from(axeCells)[0] as Cell;
    if (cell.axeEl) cell.axeEl.innerText = (sumThemes / 4).toFixed(5);
  }

  updateThemesAndAxes() {
    this.themesToUpdate.forEach((years, themeId) => {
      years.forEach(year => {
        years.delete(year);
        this.updateThemeScore(themeId, year);
      });
    })
    this.axesToUpdate.forEach((years, axeId) => {
      years.forEach(year => {
        years.delete(year);
        this.updateAxeScore(axeId, year);
      });
    })
  }

  // adds a .displayValue to be used for display
  format(cell: Cell) {

    if (cell.computeError) {
      cell.displayValue = '#ERROR';
      return;
    }
    if (!cell.formula && cell.computedValue === null) {
      cell.displayValue = 'N.C.';
      return;
    }

    const value = SpreadSheets.cellValue(cell);

    cell.displayValue = fromExcelFormat(value, cell.entity.excel_format);
  }

  formatMultiple(cells: CellMap) {
    cells.forEach(cell => this.format(cell));
  }

  keyboardEventHandler(e: KeyboardEvent) {

    // console.log(e.type, e);

    if ('F2' === e.code) {
      this.setEditMode(true);
      return;
    }

    // <ctrl/meta> @todo should be meta only if mac
    if (this.selectedCell && (e.ctrlKey || e.metaKey)) {

      const cell = this.selectedCell;

      // copying
      if (['c', 'C'].includes(e.key)) {
        navigator.clipboard.writeText(cell.computedValue.toString()).then();
        e.preventDefault();
        return;
      }

      // pasting
      if (['v', 'V'].includes(e.key)) {

        const key = cell.key;

        try {
          navigator.clipboard.readText().then(clipText => {
            clipText = clipText.replace(/[,\t ]/g, '');
            this.setCell(key, clipText)
          });
          e.preventDefault();
        } catch (e) {
          this.selectedCell.el.innerText = '';
          this.setEditMode(true);
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              const key = cell.key;
              const val = cell.el.innerText;
              this.setEditMode(false);
              this.setCell(key, parseFloat(val));
            })
          })
        }
        return;
      }

      // reverting to original value
      if (['z', 'Z'].includes(e.key)) {
        if (cell.forced) {
          cell.forced = null;
          this.computeCell(cell);
        }
        if (cell.value !== cell.originalValue) {
          this.setCell(cell.key, cell.originalValue);
        }
        e.preventDefault();
        return;
      }
    }


    // confirm edit and move down
    if ('Enter' === e.key && this.editedCell) {
      const key = this.editedCell.key;
      const val = this.editedCell.el.innerText;
      this.setEditMode(false);
      this.selectNeighbour(0, 1);
      requestAnimationFrame(() => this.setCell(key, parseFloat(val)));
      e.preventDefault();
      return;
    }

    // cancel current editing
    if ('Escape' === e.key && this.editedCell) {
      this.editedCell.el.innerText = this.editedCell.displayValue;
      this.setEditMode(false);
      e.preventDefault();
      return;
    }

    // write new value
    if (this.selectedCell && !this.editedCell && e.key.match(/^[0-9-]$/)) {
      this.selectedCell.el.innerText = '';
      this.setEditMode(true);
      return;
    }

    // only allow valid keys while editing
    if (this.editedCell && e.key.length === 1 && !e.key.match(/^[0-9-.]$/)) {
      e.preventDefault();
      return;
    }

    // move down or up while editing
    if (this.editedCell) {
      // allow jumping between edits vertically
      switch (e.code) {
        case 'ArrowDown':
          this.selectNeighbour(0, 1);
          return;
        case 'ArrowUp':
          this.selectNeighbour(0, -1);
          return;
      }
    }


    // hopping around
    if (!this.editedCell && this.selectedCell) {
      switch (e.code) {
        case 'ArrowDown':
          this.selectNeighbour(0, 1);
          return;
        case 'ArrowUp':
          this.selectNeighbour(0, -1);
          return;
        case 'ArrowLeft':
          this.selectNeighbour(-this.colsDirection, 0);
          return;
        case 'ArrowRight':
          this.selectNeighbour(this.colsDirection, 0);
          return;
      }
    }

  }

  setEditMode(mode: boolean) {
    if (this.selectedCell) {
      if (mode) {
        if (this.editedCell && this.editedCell.el) {
          this.editedCell.el.contentEditable = 'false';
        }
        this.editedCell = this.selectedCell;
        this.editedCell.el.contentEditable = 'true';
        this.editedCell.el.focus();

        // hack for focusing
        const s = window.getSelection() as Selection,
          r = document.createRange();
        r.setStart(this.editedCell.el, 0);
        r.setEnd(this.editedCell.el, 0);
        s.removeAllRanges();
        s.addRange(r);

      } else {
        if (this.editedCell) {
          this.editedCell.el.contentEditable = 'false';
          delete this.editedCell;
        }
      }
    }
  }

  getDetails(key: string): Cell {
    const cell = this.cells.get(key) as Cell;

    // explain computation
    if (cell.entity.formula) {

      const formReg = /{.*?}/g;
      cell.valuesFormula = cell.entity.formula.replace(formReg, match => {

        let year = cell.year;
        // extract -y if needed
        if (match.match(/-(\d)/)) {
          // @ts-ignore
          year -= parseInt(match.match(/-(\d)/)[1]);
          match = match.replace(/-(\d)/, '');
        }
        // build new key
        const key2 = match.substring(1, match.length - 1) + ':' + year;
        return '<var>' + (this.cells.has(key2) ? (this.cells.get(key2) as Cell).computedValue : 'NC') + '</var>';
      });
    }

    return cell;
  }

  truncate(v: number) {
    return parseFloat(v.toFixed(10));
  }

  removeYear(year: number) {
    this.cells.forEach(cell => {
      if (cell.year === year) this.unregisterCell(cell);
    })
  }
}


export default SpreadSheets;